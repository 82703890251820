body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

form {
  padding: 0px;
  margin-top: 0px;
}

form button,
form h5 {
  margin: 20px 0;
}

.collection-item:hover {
  background-color: red;
}

#thumbnail {
  display: inline-block;
}

#post-info {
  display: inline-block;
  padding-left: 10px;
}

#post-rating {
  display: inline-block;
}

#score {
  width: 36;
}

nav .sidenav-trigger {
  margin: 0;

}

nav .btn {
  padding: 0;
}

.css-selector {
  font-family: 'Roboto', sans-serif;
}